<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-10-07 16:46:29
 * @LastEditors: Please set LastEditors
 -->
<template>
  <div class="dbms">
      <heade></heade>
      <div class="box">
        <div class="con">
          <ul class="left">
            <li v-for="(i,index) in list" :key='index' @click="dian_list(index)" :class="{ 'bei':index == inde}">{{i}}</li>
          </ul>
          <div class="right">
            <router-view></router-view>
          </div>
        </div>
      </div>
      <foote></foote>
  </div>
</template>

<script>
import heade from '../components/Heade'
import foote from '../components/Foote'
export default {
  name: 'dbms',
  data () {
    return {
      list: [
        '目标',
        '销售',
        '产品',
        '核算',
        '风控',
        '成长'
      ],
      inde: ''
    }
  },
  components: { heade, foote },
  created () {
    if (sessionStorage.getItem('pc_pc_pc_shujuguanli') != null && sessionStorage.getItem('pc_pc_pc_shujuguanli') != undefined && sessionStorage.getItem('pc_pc_pc_shujuguanli') != '') {
      this.inde = JSON.parse(sessionStorage.getItem('pc_pc_pc_shujuguanli'))
    } else {
      this.inde = 0
    }
  },
  methods: {
    dian_list (index) {
      this.inde = index
      if (this.inde == 0) {
        this.$router.push('/targetb')
      } else if (this.inde == 1) {
        this.$router.push('/saleb')
      } else if (this.inde == 2) {
        this.$router.push('/productb')
      } else if (this.inde == 3) {
        this.$router.push('/accountingb')
      } else if (this.inde == 4) {
        this.$router.push('/managementb')
      } else if (this.inde == 5) {
        this.$router.push('/growupb')
      }
      sessionStorage.setItem('pc_pc_pc_shujuguanli', JSON.stringify(this.inde))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dbms {
  min-height: 100vh;
  background:#E7E8EB;
  .box{
    padding-top: 1.96rem;
    background:#E7E8EB;
    margin-bottom: 0.22rem;
    .con{
      width:12rem;
      min-height:731px;
      margin:0 auto;
      background: #fff;
      display: flex;
      justify-content: left;
      .left{
          width: 2.2rem;
          min-height:100%;
          background-color: #F4F6F8;
          li{
              height:0.6rem;
              width: 100%;
              color: #888888;
              text-align: center;
              line-height: 0.6rem;
              font-size: 0.16rem;
              cursor:pointer;
          }
          .bei{
              background: #979AA1;
              color: #fff;
          }
      }
      .right{
        min-height: 731px;
        width:9.8rem;
      }
    }
    // .tit{
    //   font-size: 0.18rem;
    //   color:#1A2533;
    //   border-bottom: 0.01rem solid #EEEEEE;
    // }
  }
}
</style>
